import { useContext } from "react";
import WebplaceContext from "../../../store/webplace-context";

import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TikTok from "../../../assets/mainItems/tiktokLogo.svg";
import PoweredHubster from "../../../assets/mainItems/poweredHubster.svg";
import PoweredOtter from "../../../assets/mainItems/poweredOtter.svg";

import "./Footer.css";

const Footer = () => {
  const footerLocationsCtx =
    useContext(WebplaceContext).locationInformation.locations;
  const footerInfoCtx = useContext(WebplaceContext).footerInformation;
  const contactCtx = useContext(WebplaceContext).homeSocials;
  const stylesCtx = useContext(WebplaceContext).footerInformation.footer_styles;

  const phone = footerLocationsCtx[0].phone;
  let poweredBy;

  if (footerInfoCtx.powered_by_hubster === footerInfoCtx.powered_by_otter) {
    poweredBy = <></>;
  } else if (
    footerInfoCtx.powered_by_hubster &&
    !footerInfoCtx.powered_by_otter
  ) {
    poweredBy = (
      <img src={PoweredHubster} alt="Powered by" className="powered-by" />
    );
  } else {
    poweredBy = (
      <img src={PoweredOtter} alt="Powered by" className="powered-by" />
    );
  }

  return (
    <div
      className="footer-wrapper"
      style={{
        backgroundColor: stylesCtx.background_color,
        color: stylesCtx.subtitle_font_color,
      }}
      aria-label="footer"
    >
      <div className="footer-title" role="heading" aria-level="2">{footerInfoCtx.title}</div>
      <div className="footer-info-wrapper">

        {/* Contact information */}
        {footerInfoCtx.show_contact_option && (
          <div>
            <div className="info-title" role="heading" aria-level="3">
              {footerInfoCtx.contact_info_subtitle}
            </div>
            {footerLocationsCtx.length === 1 && (
              <a
                className="info-data"
                style={{ color: stylesCtx.info_font_color }}
                href={`tel: ${phone}`}
              >
                <div
                  className="info-data"
                  style={{ color: stylesCtx.info_font_color }}
                >
                  {phone}
                </div>
              </a>
            )}
            <div
              className="info-data"
              style={{ color: stylesCtx.info_font_color }}
            >
              {contactCtx.contact_email}
            </div>
          </div>
        )}

        {/* Social media & powered by */}
        <div className="socials-powered-wrapper">
          <div className="social-media-wrapper">
            {contactCtx.instagram_url.length > 0 && (
              <div>
                <a
                  href={contactCtx.instagram_url}
                  target="_self"
                  rel="noopener noreferrer"
                  className="anchor-social"
                  aria-label="Visit our Instagram page"
                >
                  <InstagramIcon fontSize="inherit" />
                </a>
              </div>
            )}
            {contactCtx.facebook_url.length > 0 && (
              <div>
                <a
                  href={contactCtx.facebook_url}
                  target="_self"
                  rel="noopener noreferrer"
                  className="anchor-social"
                  aria-label="Visit our Facebook page"
                >
                  <FacebookIcon fontSize="inherit" />
                </a>
              </div>
            )}
            {contactCtx.tiktok_url.length > 0 && (
              <div>
                <a
                  href={contactCtx.tiktok_url}
                  target="_self"
                  rel="noopener noreferrer"
                  className="anchor-social"
                  aria-label="Visit our TikTok page"
                >
                  <img
                    src={TikTok}
                    alt="tiktok"
                    className="anchor-social-img"
                  />
                </a>
              </div>
            )}
          </div>
          <div className="powered-by-wrapper">
            {poweredBy}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
