import Navbar from "../../components/ui/navbar/Navbar";

import "./AboutUs.css";

import AboutUs1 from "../../assets/mainItems/aboutUs1.jpg"
import AboutUs2 from "../../assets/mainItems/aboutUs2.png"


const AboutUs = () => {
  return (
    <div>
      <a href="#main-about" className="skip-to-main">Skip to main content</a>
      <Navbar />
      <div className="spacer"></div>
      <div className="aboutus-wrapper" aria-label="about-us-view" id="main-about">
        <h1 className="aboutus-title">A Taco-Lovers Story</h1>
        <div className="aboutus-inner-wrapper">


          <div className="aboutus-first-inner-wrapper">

            <div className="aboutus-inner-inner-wrapper aboutus-first-inner-inner-wrapper" role="heading" aria-level="2"><b><i>“¿Quien tiene los mejores tacos en San Jose?
              ¿Y los mejores burritos?”</i></b>
              <div>
                “Who has the best tacos in San Jose? And the best burritos?” It was a phrase repeated on an almost weekly basis by Silicon Valley engineer Frank Araujo. A native of Mexico, Mr. Araujo came to the US at age 14, seeking opportunity. Through hard work and plenty of divine blessings, he built the life of his dreams – but one thing was missing. His new hometown just couldn’t deliver the authentic Mexican flavors he craved.
              </div>
              <div>So, he did what any techie with a taco craving would do: he engineered a solution. Mr. Araujo set to work gathering the best taqueros and honing the best recipes to deliver San Jose’s best tacos and burritos. In 2005, he took a leap of faith opening the El Paisa mobile kitchen. This 28-foot long, glass-fronted taco trailer was a fast hit. Lines stretched down the block as our hand-made tortillas and fresh grilled carne asada attracted a loyal following across the South Bay. Soon, one El Paisa trailer became two, and then four.</div>
            </div>

            <div className="first-aboutus-image-wrapper">
              <img src={AboutUs1} alt="The original Mobile Kitchen" className="aboutus-image first-aboutus-image" />
              <div>The original “El Paisa” Mobile Kitchen (circa 2006)</div>
            </div>

          </div>

          <div className="aboutus-inner-inner-wrapper">
            <div>
              With the proof of concept established, it was soon time to trade a few of taco trucks for a full-fledged restaurant. Araujo’s Mexican Grill’s first permanent location opened at 3070 Senter Rd in San Jose in 2008 and quickly became a late-night foodie hangout and one of the Bay Area’s best taquerias.
            </div>
            <div>In 2012, another location followed at the famous intersection of King and Story – the heart of Latin culture in the South Bay.</div>
            <div>In 2018, the taco shop founded by a Silicon Valley engineer finally made the jump online and debuted on DoorDash, GrubHub, and UberEats. These platforms introduced our authentic Mexican street food to a much wider audience. Suddenly, instead of just being the best taco shop on the East Side, we were simultaneously delivering the best burritos in Almaden and the best tacos in Willow Glen. Araujo’s quickly became one of the most popular independent restaurants on DoorDash – and we were invited to join their national Restaurant Advisory Committee in 2019.</div>
            <div>After analyzing delivery trends, we realized we needed to expand our reach further north, and in 2021, while many restaurants were closing due to the pandemic, we broke ground on a new flagship location at 1501 Berryessa Rd in North San Jose. This store, with its high-end finishes and massive HD screens, will be the model for Araujo’s Mexican Grill’s future look and feel, but at our heart (and in our kitchens) we will always remain true to our taco truck roots: offering authentic Mexican flavors made from scratch with the highest quality and freshest ingredients.</div>
            <img src={AboutUs2} alt="Araujo's restaurant" className="aboutus-image second-aboutus-image" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
